export enum Experiment {
  EnableCSSOptimization = 'specs.myAccount.EnableCSSOptimization',
  EnableOOIInEditor = 'specs.membersArea.framelessEditorMyAccount',
  ChangeLoginInfo = 'specs.membersArea.ChangeLoginInfo',
  EnableSettingsResetButton = 'specs.myAccount.EnableDesignTabResetButtonPerPage',
  EnableNewSettings = 'specs.myAccount.UseNewSettings',
  ShowBlockedMembersModalEmptyState = 'specs.myAccount.ShowBlockedMembersModalEmptyState',
  ShowPrivacySettingsMessageForSiteOwners = 'specs.myAccount.ShowPrivacySettingsMessageForSiteOwners',
  EnableButtonTextSetting = 'specs.myAccount.ShowButtonTextSetting',
  EnableUrlEditNote = 'specs.myAccount.EnableUrlEditNote',
}
